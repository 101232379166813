<template>
    <div class='out_storage'>
        <a-modal 
            :title="title" 
            :confirm-loading="confirmLoading" 
            @ok="closeDialog"
            v-model:visible="showDialog">
                <m-dialog-content  
                :rules="rules"
                :forms="forms"
                :readOnly="true"
                :formItems="popForms"/>
            </a-modal>
         <a-modal 
            title="代理信息" 
            :confirm-loading="confirmLoading" 
            @ok="submitAgent"
            v-model:visible="showCheckDialog">
                <m-dialog-content  
                :rules="rules"
                :forms="forms"
                :readOnly="false"
                :formItems="checkForms"/>
            </a-modal>
        <m-form
            :formItems="formItems"
            @importAgent="importAgent" 
            @loadData="loadData(1)"></m-form>
        <m-table 
            :buttons="buttons" 
            :loading="loading" 
            :columns="columns" 
            :data="tableList" 
            :totals="total"
            :currentPage="currentPage"
            @pageChange="loadData($event)"
            @modifyAgent="modifyAgent($event)"
            @detail="detail($event)"></m-table>
    </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { isValid, importAgentList, importAgentDetail, importAgentCreate, importAgentModify, agentSettlerTypes, saleModelOptionsApi } from '../../utils/api'
import { assginDataToArray, formatTime, geneIndex } from '@/utils/util'
import { message } from 'ant-design-vue'
export default {
    name: '',
    setup() {
         const state= reactive({
             title: '详情',
             confirmLoading: false,
             showDialog: false,
             buttons: [
                 {text: '详情', eventName: 'detail'},
                //  {text: '修改', eventName: 'modifyAgent'}
             ],
             deliverer: {value: '', options: []},
             columns: [
                 {title: '序号', dataIndex: 'index', key: 'index'},
                 {title: '订单编号', dataIndex: 'id', key: 'id'},
                 {title: '代理姓名', dataIndex: 'realName', key: 'realName'},
                 {title: '联系电话', dataIndex: 'phone', key: 'phone'},
                 {title: '导入时间', dataIndex: 'createTime', key: 'createTime'},
                 {title: '修改时间', dataIndex: 'updateTime', key: 'updateTime'},
                 {title: '备注', dataIndex: 'remark', key: 'remark'},
                 {title: '状态', dataIndex: 'statusText', key: 'statusText'},
                 {title: '操作', dataIndex: 'manage', key: 'manage', slots: {customRender: 'actions'}}
             ],
             formItems: [
                 {type: 'input', label: '代理姓名', value: undefined},
                 {type: 'input', label: '手机号码', value: undefined},
                 {type: 'selector', key: 'value', options: [{label: '初始化', value: 0}, {label: '已登录', value: 1}, {label: '禁用', value: 2}, {label: '已删除', value: 3}], label: '代理状态', value: undefined},
                 {type: 'button', eventName: 'loadData', label: '搜索'},
                 {type: 'button', eventName: 'importAgent', label: '导入代理'},
             ],
             checkForms: [
                 {label: '代理姓名', prop: 'realName', value: '', type: 'input'},
                 {label: '联系电话', prop: 'phone', value: '', type: 'input'},
                 {label: '备注', prop: 'remark', value: '', type: 'input'},
                 {label: '结算比例', prop: 'ownRate', value: '', type: 'input'},
                 {label: '结算类型', type: 'selector', value: '', key: 'value', prop: 'settlerType', options: []},
                 {label: '代理状态', type: 'selector', value: '', key: 'value', prop: 'status', options: [{labelName: '初始化', value: 0}, {labelName: '已登录', value: 1}, {labelName: '禁用', value: 2}, {labelName: '删除', value: 3}]},
                 {label: '销售模式', type: 'selector', value: '', key: 'value', prop: 'status', options: []},
             ],
             popForms: [
                 {label: '订单编号', prop: 'id', value: '', type: 'input'},
                 {label: '代理姓名', prop: 'realName', value: '', type: 'input'},
                 {label: '联系电话', prop: 'phone', value: '', type: 'input'},
                 {label: '导入时间', prop: 'createTime', value: '', type: 'input'},
                 {label: '修改时间', prop: 'updateTime', value: '', type: 'input'},
                 {label: '备注', prop: 'remark', value: '', type: 'input'},
                 {label: '状态', prop: 'statusText', value: '', type: 'input'},
            ],
             forms: {timeout: ''},
             rules: {timeout: [{ required: true, message: '请输入超时时间', trigger: 'blur'}]},
             tableList: [],
             currentPage: 1,
             pageSize: 10,
             total: 0,
             selectedIDs: [],
             loading: false,
             status: 0,
             detail: {},
             isCreate: false,
             showCheckDialog: false,
             settleTypes: [],
        })
        async function  loadData(pageNum = null) {
            try {
                state.loading = true
                state.currentPage = pageNum || state.currentPage
                const items = state.formItems
                const params = {
                    pageNum: state.currentPage,
                    pageSize: state.pageSize,
                    realName: items[0].value,
                    phone: items[1].value,
                    status: items[2].value
                }
                const result = await importAgentList(params)
                state.loading = false
                if(isValid(result)) {
                    state.total = result.data.total
                    state.tableList = geneIndex(result.data.list, state.pageSize, state.currentPage)
                    state.tableList.forEach(ele => {
                        const s = ele.status
                        ele.createTime = formatTime(ele.createTime)
                        ele.updateTime = formatTime(ele.updateTime)
                        ele.statusText = s == 0 ? '初始化'
                                        :s == 1 ? '已登录'
                                        :s == 2 ? '已禁用'
                                        :s == 3 ? '已删除' : ''
                    })
                }
            } catch(e) {
                state.loading = false
                console.error(e)
            }
        }
        loadData()
        function importAgent() {
            state.checkForms.forEach(ele => ele.value = '')
            state.showCheckDialog = true 
            state.isCreate = true
        }
        // 获取代理结算类型
        async function getSettlerTypes() {
            try {
                const result = await agentSettlerTypes()
                if (isValid(result)) {
                    console.log(result.data)
                    result.data.forEach(ele => {
                        const {settlerType, name} = ele
                        state.settleTypes.push({labelName: name, value: settlerType})
                    })
                    state.checkForms[4].options = state.settleTypes
                }
            } catch(e) {
                console.error(e)
            }
        }
        //获取销售模式
        async function getSaleModelOptions(){
            try {
                const res = await saleModelOptionsApi()
                if(res.code == 0){
                    console.log(res);
                    res.data.forEach(item => {
                        const {label, value} = item
                        state.checkForms[6].options.push({labelName: label, value})
                    })
                }
            } catch (error) {
                console.log(error);
            }
        }
        getSaleModelOptions()
        getSettlerTypes()
        async function detail(event) {
            try {
                const result = await importAgentDetail(event.id)
                if (isValid(result)) {
                    const ele = result.data 
                    const s = ele.status
                    ele.createTime = formatTime(ele.createTime)
                    ele.updateTime = formatTime(ele.updateTime)
                    ele.statusText = s == 0 ? '初始化'
                                        :s == 1 ? '已登录'
                                        :s == 2 ? '已禁用'
                                        :s == 3 ? '已删除' : ''
                    state.popForms = assginDataToArray(result.data, state.popForms)
                    state.showDialog = true
                }
            } catch(e) {
                console.error(e)
            }
        }

        function modifyAgent(event) {
            state.detail = event
            state.checkForms.forEach(ele => {
                ele.value = event[ele.prop]
            })
            state.isCreate = false 
            state.showCheckDialog = true
        }
       
        function closeDialog() {
            loadData()
            state.showDialog = false
        }

        async function submitAgent() {
            try {
                const items = state.checkForms
                if (items[3].value < 0 || items[3].value > 7) {
                    message.warn(`结算比例需为在0-7之间的数值`)
                    return
                }
                const params = {
                    realName: items[0].value,
                    phone: items[1].value,
                    remark: items[2].value,
                    ownRate: items[3].value,
                    settlerType: items[4].value,
                    saleModel:items[6].value
                }
                console.log(state.detail)
                const result = state.isCreate ? await importAgentCreate(params)
                                              : await importAgentModify(
                                                  Object.assign(params, {
                                                      id: state.detail.id,
                                                      status: items[4].value
                                                  }))
                if (isValid(result)) {
                    const info = state.isCreate ? '导入成功' : '修改成功'
                    message.success(info)
                    loadData()
                    state.showCheckDialog =false
                }
            } catch(e) {
                console.error(e)
            }
        }
        
        return {
            ...toRefs(state),
            detail,
            loadData,
            closeDialog,
            importAgent,
            modifyAgent,
            submitAgent
        }
    },
}
</script>
<style lang='scss' scoped>
</style>